exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-activity-tsx": () => import("./../../../src/pages/activity.tsx" /* webpackChunkName: "component---src-pages-activity-tsx" */),
  "component---src-pages-care-tsx": () => import("./../../../src/pages/care.tsx" /* webpackChunkName: "component---src-pages-care-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feed-join-tsx": () => import("./../../../src/pages/feed-join.tsx" /* webpackChunkName: "component---src-pages-feed-join-tsx" */),
  "component---src-pages-feed-tsx": () => import("./../../../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../../../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-organization-tsx": () => import("./../../../src/pages/organization.tsx" /* webpackChunkName: "component---src-pages-organization-tsx" */),
  "component---src-pages-site-tsx": () => import("./../../../src/pages/site.tsx" /* webpackChunkName: "component---src-pages-site-tsx" */),
  "component---src-pages-source-tsx": () => import("./../../../src/pages/source.tsx" /* webpackChunkName: "component---src-pages-source-tsx" */),
  "component---src-pages-unit-tsx": () => import("./../../../src/pages/unit.tsx" /* webpackChunkName: "component---src-pages-unit-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-activity-test-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/activity/test.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-activity-test-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-care-ec-388393-67-b-1-4-da-1-9018-4-bef-03-f-49441-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/care/ec388393-67b1-4da1-9018-4bef03f49441.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-care-ec-388393-67-b-1-4-da-1-9018-4-bef-03-f-49441-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-company-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/company.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-company-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-information-test-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/information/test.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-information-test-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-1-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/news/news1.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-1-md" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-2-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/news/news2.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-2-md" */),
  "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-4-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/jenkins/.jenkins/workspace/niu-web/src/md-pages/news/news4.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-md-pages-news-news-4-md" */)
}

